<template>
  <div>
    <div class="about-support">
      <div class="about-support-title">Meet our team</div>
      <div class="about-support-des">
        We started with a little white card reader but haven’t stopped there.
        Our purpose is economic empowerment, and we’re building tools to help
        sellers of all sizes succeed on their own terms.
      </div>
      <!-- <div class="about-support-detail">
        <div class="about-support-detail-title">View benifits details</div>
        <img
          src="../assets/images/weview.svg"
          style="cursor: poiter"
          class="open-icon"
          alt=""
        />
      </div> -->
      <div class="popup-about-support">
        <img src="../assets/images/icon-close.svg" class="close-icon" alt="" />
        <div class="popup-content">
          <div class="popup-content-heading">
            Helping local businesses worldwide.
          </div>
          <div class="popup-content-main">
            We started with a little white card reader but haven’t stopped
            there. Our purpose is economic empowerment, and we’re building tools
            to help sellers of all sizes succeed on their own terms.
          </div>
        </div>
      </div>
    </div>
    <aboutMember />
  </div>
</template>

<script>
import aboutMember from "../component-about/aboutMember.vue";
export default {
  components: {
    aboutMember,
  },
  mounted() {
    let popupstory = document.querySelector(".popup-about-support");
    // let openIcon = document.querySelector(".open-icon");
    let closeIcon = document.querySelector(".close-icon");
    var bodyClose = document.querySelector("body");
    // openIcon.addEventListener("click", () => {
    //   popupstory.style.transform = "translateY(0)";
    //   bodyClose.style.overflow = "hidden";
    // });
    closeIcon.addEventListener("click", () => {
      popupstory.style.transform = "translateY(100%)";
      bodyClose.style.overflow = "auto";
    });
  },
};
</script>

<style lang="scss" scoped>
.about-support {
  padding-left: 20px;
  padding-right: 20px;
  color: #363636;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 11rem;
  .about-support-title {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 6.7rem;
    max-width: 758px;
  }
  &-des {
    font-size: 2.4rem;
    color: #999999;
    line-height: 3.6rem;
    font-weight: 400;
    margin-top: 3.2rem;
    max-width: 886px;
  }

  .about-support-detail {
    margin-top: 32px;
    margin-bottom: 113px;
    text-align: center;
    &-title {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    img {
      margin-top: 11px;
    }
  }
  .popup-about-support {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 4000;
    overflow: auto;
    padding: 80px 0;
    transform: translateY(100%);
    transition: all 0.5s linear;
    .popup-content-heading {
      text-align: center;
      font-size: 50px;
      line-height: 44px;
      font-weight: 600;
      color: #1a1a1a;
    }
    .popup-content-main {
      font-size: 30px;
      text-align: center;
      font-weight: 400;
      font-style: italic;
      max-width: 1020px;
      margin: 0 auto;
      margin-top: 60px;
      display: grid;
      grid-gap: 30px;
      color: #1a1a1a;
    }
  }
  .close-icon {
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
@media (max-width: 1100px) {
  .about-suppor {
    .about-support {
      padding: 60px 0;
      .popup-content-heading {
        text-align: center;
        font-size: 24px;
        line-height: 34px;
      }
      .popup-content-main {
        max-width: 1020px;
        padding: 0 20px;
        margin-top: 30px;
        font-size: 20px;
      }
    }
  }
}
:v-deep.teammate {
  :v-deep.teammate-content {
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .about-support {
    .about-support-title {
      font-size: 3.6rem;
      line-height: 4.32rem;
      margin-top: 4.8rem;
      max-width: 758px;
      padding: 0 20px;
    }
  }
  @media (max-width: 739px) {
    .word-wrap {
      display: block;
    }
  }
}
@media (max-width: 739px) {
  .about-support {
    padding-top: 0;
    .about-support-title {
      margin-top: 6.4rem;
    }
    &-des {
      margin-top: 2.4rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}
</style>
