<template>
  <div class="we-believe">
    <div class="we-believe-title">
      <div style="display: flex; justify-content: center">
        <div class="we-believe-title-header">
          We believe in being fair and Beedu.
        </div>
      </div>
      <div class="we-believe-title-des">
        We’re building an inclusive economy where all sellers have equal access
        to opportunity. We strive to live by these same values in all our
        workplaces.
      </div>
    </div>
    <div class="we-believe-content">
      <div class="sack-one">
        <img src="../assets/images/we1.svg" alt="" />
        <div class="sack-one-des">Competitive compensation</div>
      </div>
      <div class="sack-one">
        <img src="../assets/images/we2.svg" alt="" />
        <div class="sack-one-des">Healthcare coverage</div>
      </div>
      <div class="sack-one">
        <img src="../assets/images/we3.svg" alt="" />
        <div class="sack-one-des">Wellness perks</div>
      </div>
      <div class="sack-one">
        <img src="../assets/images/we4.svg" alt="" />
        <div class="sack-one-des">Paid parental leave</div>
      </div>
      <div class="sack-one">
        <img src="../assets/images/we5.svg" alt="" />
        <div class="sack-one-des">Employee stock purchase plan</div>
      </div>
      <div class="sack-one">
        <img src="../assets/images/we6.svg" alt="" />
        <div class="sack-one-des">Paid time off</div>
      </div>
      <div class="sack-one">
        <img src="../assets/images/we7.svg" alt="" />
        <div class="sack-one-des">Learning and development</div>
      </div>
      <div class="sack-one">
        <img src="../assets/images/we8.svg" alt="" />
        <div class="sack-one-des">Mental health</div>
      </div>
    </div>
    <div class="web-believe-detail">
      <div class="web-believe-detail-title">View benifits details</div>
      <img
        style="cursor: pointer"
        class="icon-click-detail"
        src="../assets/images/weview.svg"
        alt=""
      />
    </div>
    <div class="popup-we-believe">
      <img src="../assets/images/icon-close.svg" class="icon-close" alt="" />
      <div class="popup-we-believe-header">Be well and thrive.</div>
      <div class="popup-we-believe-content">
        <div class="popup-one">
          <img src="../assets/images/we1.svg" alt="" />
          <div class="popup-one-des">Competitive compensation</div>
          <div class="popup-one-note">
            A In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
        <div class="popup-one">
          <img src="../assets/images/we2.svg" alt="" />
          <div class="popup-one-des">Healthcare coverage</div>
          <div class="popup-one-note">
            B In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
        <div class="popup-one">
          <img src="../assets/images/we3.svg" alt="" />
          <div class="popup-one-des">Wellness perks</div>
          <div class="popup-one-note">
            C In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
        <div class="popup-one">
          <img src="../assets/images/we4.svg" alt="" />
          <div class="popup-one-des">Paid parental leave</div>
          <div class="popup-one-note">
            D In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
        <div class="popup-one">
          <img src="../assets/images/we5.svg" alt="" />
          <div class="popup-one-des">Employee stock purchase plan</div>
          <div class="popup-one-note">
            E In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
        <div class="popup-one">
          <img src="../assets/images/we6.svg" alt="" />
          <div class="popup-one-des">Paid time off</div>
          <div class="popup-one-note">
            F In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
        <div class="popup-one">
          <img src="../assets/images/we7.svg" alt="" />
          <div class="popup-one-des">Learning and development</div>
          <div class="popup-one-note">
            G In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
        <div class="popup-one">
          <img src="../assets/images/we8.svg" alt="" />
          <div class="popup-one-des">Mental health</div>
          <div class="popup-one-note">
            H In addition to a base salary, our equity plans allow Squares to
            participate in ownership and achievement in our company.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
  mounted() {
    let popup = document.querySelector(".popup-we-believe");
    let clickDetail = document.querySelector(".icon-click-detail");
    let iconClose = document.querySelector(".icon-close");
    let body = document.querySelector("body");
    clickDetail.addEventListener("click", () => {
      popup.style.transform = "translateY(0)";
      body.style.overflow = "hidden";
    });
    iconClose.addEventListener("click", () => {
      popup.style.transform = "translateY(100%)";
      body.style.overflow = "auto";
    });
  },
};
</script>

<style lang="scss" scoped>
.we-believe {
  .we-believe-title {
    padding: 110px 0;
    padding-bottom: 82px;
    max-width: 602px;
    margin: 0 auto;
    text-align: center;

    &-header {
      font-size: 5.6rem;
      line-height: 120%;
      font-weight: 600;
      color: #363636;
    }
    &-des {
      font-size: 2.4rem;
      line-height: 150%;
      font-weight: 400;
      color: #999999;
      margin-top: 3.2rem;
    }
  }
  .we-believe-content {
    max-width: 1167px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 71px;

    grid-template-areas:
      "h1 h2 h3 h4"
      "h5 h6 h7 h8";
    .sack-one {
      max-width: 273px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #363636;
    }
    .sack-one:nth-child(1) {
      grid-area: h1;
    }
    .sack-one:nth-child(2) {
      grid-area: h2;
    }
    .sack-one:nth-child(3) {
      grid-area: h3;
    }
    .sack-one:nth-child(4) {
      grid-area: h4;
    }
    .sack-one:nth-child(5) {
      grid-area: h5;
    }
    .sack-one:nth-child(6) {
      grid-area: h6;
    }
    .sack-one:nth-child(7) {
      grid-area: h7;
    }
    .sack-one:nth-child(8) {
      grid-area: h8;
    }
    .sack-one img {
      flex-shrink: 0;
      width: 183px;
      height: 61.44px;
    }
    .sack-one-des {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      flex: 1;
      margin-top: 8px;
    }
  }

  .web-believe-detail {
    margin-top: 64px;
    margin-bottom: 113px;
    text-align: center;
    &-title {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    img {
      margin-top: 11px;
    }
  }
  .popup-we-believe {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 4000;
    overflow: auto;
    padding: 80px 0;
    transform: translateY(100%);
    transition: all 0.5s linear;
    .popup-we-believe-header {
      text-align: center;
      font-size: 32px;
      line-height: 44px;
      font-weight: 600;
    }
    .popup-we-believe-content {
      max-width: 1020px;
      margin: 0 auto;
      margin-top: 60px;
      display: grid;
      grid-gap: 30px;
      grid-template-areas:
        "h1 h2"
        "h3 h4"
        "h5 h6"
        "h7 h8";
    }
    .popup-one {
      max-width: 493px;
      display: flex;
      flex-direction: column;
      align-items: start;
      color: #363636;
    }
    .popup-one:nth-child(1) {
      grid-area: h1;
    }
    .popup-one:nth-child(2) {
      grid-area: h2;
    }
    .popup-one:nth-child(3) {
      grid-area: h3;
    }
    .popup-one:nth-child(4) {
      grid-area: h4;
    }
    .popup-one:nth-child(5) {
      grid-area: h5;
    }
    .popup-one:nth-child(6) {
      grid-area: h6;
    }
    .popup-one:nth-child(7) {
      grid-area: h7;
    }
    .popup-one:nth-child(8) {
      grid-area: h8;
    }
    .popup-one-note {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
    }
    .popup-one-des {
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 20px;
    }
  }
  img.icon-close {
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
@media (max-width: 1100px) {
  .we-believe {
    .popup-we-believe {
      padding: 60px 0;
      .popup-we-believe-header {
        text-align: center;
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
      }
      .popup-we-believe-content {
        max-width: 1020px;
        padding: 0 20px;
        margin-top: 30px;
        display: grid;
        grid-gap: 30px;
        grid-template-areas:
          "h1"
          "h2"
          "h3"
          "h4"
          "h5"
          "h6"
          "h7"
          "h8";
      }
      .popup-one {
        max-width: 493px;
        display: flex;
        flex-direction: column;
        align-items: start;
        color: #363636;
      }
      .popup-one:nth-child(1) {
        grid-area: h1;
      }
      .popup-one:nth-child(2) {
        grid-area: h2;
      }
      .popup-one:nth-child(3) {
        grid-area: h3;
      }
      .popup-one:nth-child(4) {
        grid-area: h4;
      }
      .popup-one:nth-child(5) {
        grid-area: h5;
      }
      .popup-one:nth-child(6) {
        grid-area: h6;
      }
      .popup-one:nth-child(7) {
        grid-area: h7;
      }
      .popup-one:nth-child(8) {
        grid-area: h8;
      }
      .popup-one-note {
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
      }
      .popup-one-des {
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 739px) {
  .we-believe {
    .we-believe-title {
      padding: 64px 20px 54px 20px;
      max-width: 570px;
      margin: 0 auto;
      text-align: center;

      &-header {
        font-size: 3.6rem;
        line-height: 4.3rem;
        width: 330px;
      }
      &-des {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-top: 2.4rem;
      }
    }
    .we-believe-content {
      padding-left: 10px;
      width: 280px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 48px;
      grid-template-areas:
        "h1 h2"
        "h3 h4"
        "h5 h6"
        "h7 h8";
      .sack-one {
        max-width: 273px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .sack-one:nth-child(1) {
        grid-area: h1;
      }
      .sack-one:nth-child(2) {
        grid-area: h2;
      }
      .sack-one:nth-child(3) {
        grid-area: h5;
      }
      .sack-one:nth-child(4) {
        grid-area: h6;
      }
      .sack-one:nth-child(5) {
        grid-area: h3;
      }
      .sack-one:nth-child(6) {
        grid-area: h4;
      }
      .sack-one:nth-child(7) {
        grid-area: h7;
      }
      .sack-one:nth-child(8) {
        grid-area: h8;
      }
      .sack-one img {
        flex-shrink: 0;
        width: 183px;
        height: 61.44px;
      }
      .sack-one-des {
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;
        flex: 1;
        margin-top: 8px;
      }
    }
    .web-believe-detail {
      margin-top: 56px;
      margin-bottom: 64px;
      text-align: center;
      &-title {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      img {
        margin-top: 11px;
      }
    }
  }
}
</style>
