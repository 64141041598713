<template>
  <div id="team-slide" class="slide-team" @mouseleave="handleHoverOut">
<!--    <VueSlickCarousel-->
<!--      class="slide-slick-carousel"-->
<!--      v-bind="settings"-->
<!--      ref="carousel"-->
<!--      :dots="true"-->
<!--    >-->
<!--      <div class="list-images" @mouseover="handleHover">-->
<!--        <img-->
<!--          class="image-carousel"-->
<!--          src="../assets/images/teamwork1.jpg"-->
<!--          alt=""-->
<!--        />-->
<!--      </div>-->
<!--      <div class="list-images" @mouseover="handleHover">-->
<!--        <img-->
<!--          class="image-carousel"-->
<!--          src="../assets/images/teamwork5.jpeg"-->
<!--          alt=""-->
<!--        />-->
<!--      </div>-->
<!--      <div class="list-images" @mouseover="handleHover">-->
<!--        <img-->
<!--          class="image-carousel"-->
<!--          src="../assets/images/teamwork2.jpg"-->
<!--          alt=""-->
<!--        />-->
<!--      </div>-->
<!--      <div class="list-images" @mouseover="handleHover">-->
<!--        <img-->
<!--          class="image-carousel"-->
<!--          src="../assets/images/teamwork4.jpeg"-->
<!--          alt=""-->
<!--        />-->
<!--      </div>-->
<!--      <div class="list-images" @mouseover="handleHover">-->
<!--        <img-->
<!--          class="image-carousel"-->
<!--          src="../assets/images/teamwork.jpg"-->
<!--          alt=""-->
<!--        />-->
<!--      </div>-->
<!--    </VueSlickCarousel>-->
<!--    <div v-if="isScale" class="btn-nav" @mouseover="handleHover">-->
<!--      <button class="btn-prev" @click="handleClickPrev"></button>-->
<!--      <button class="btn-next" @click="handleClickNext"></button>-->
<!--    </div>-->
  </div>
</template>
<script>

export default {
  name: "MyComponent",
  components: {  },
  data() {
    return {
      isScale: false,
      settings: {
        dots: true,
        infinite: true,
        centerMode: true,
        centerPadding: "200px",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              centerPadding: "10px",
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 539,
            settings: {
              centerMode: true,
              slidesToShow: 1,
              centerPadding: "10px",
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 320,
            settings: {
              infinite: true,
              centerMode: false,
              slidesToShow: 1,
              centerPadding: "10px",
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    handleHover() {
      this.isScale = true;
    },
    handleHoverOut() {
      this.isScale = false;
    },
    handleClickPrev() {
      if (this.$refs.carousel) {
        this.$refs.carousel.prev();
      }
    },
    handleClickNext() {
      if (this.$refs.carousel) {
        this.$refs.carousel.next();
      }
    },
  },
  computed: {
    getSettings() {
      let finalSettings = {};
      let settings = {
        dots: true,
        infinite: true,
        centerMode: true,
        centerPadding: "200px",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              centerPadding: "10px",
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 414,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: false,
            },
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
              centerMode: true,
            },
          },
        ],
      };
      if (this.isScale) {
        finalSettings = {
          ...settings,
          arrows: true,
        };
      } else {
        finalSettings = {
          ...settings,
          arrows: false,
        };
      }
      return finalSettings;
    },
  },
};
</script>
<style scoped>
.image-carousel {
  width: 100%;
  transition: transform 0.4s ease;
  transform-origin: 50% 50%;
  max-width: 1020px;
  max-height: 650px;
  object-fit: cover;
  padding: 5px;
  transition: 0.3s linear;
  opacity: 0.2s ease-in-out;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
.btn-nav {
}
.btn-prev,
.btn-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 25%;
  height: 100%;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 100;
}
.btn-next {
  right: 50px;
}
.btn-prev {
  left: 50px;
}

.btn-nav .btn-prev::before {
  position: absolute;
  left: 50px;
  bottom: 50%;
  margin-top: -70px;
  content: url(../assets/images/next02.png);
}
.btn-nav .btn-next::before {
  position: absolute;
  right: 50px;
  bottom: 50%;
  margin-top: -70px;
  content: url(../assets/images/prev02.png);
}
@media (max-width: 912px) {
  .btn-next::before {
    opacity: 0 !important;
  }
  .btn-prev::before {
    opacity: 0 !important;
  }
}
@media (max-width: 1024px) {
  .image-carousel {
    width: 1024px !important;
  }
  .btn-next::before {
    opacity: 0 !important;
  }
  .btn-prev::before {
    opacity: 0 !important;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .btn-next::before {
    right: 0px !important;
  }
  .btn-prev::before {
    left: 0px !important;
  }
}
</style>
