<template>
  <div class="about-baner">
    <img
      src="../assets/images/bannerAbout.svg"
      class="is-hidden-mobile"
      alt=""
    />
    <img
      src="../assets/images/aboutBannerMobile.svg"
      class="is-hidden-pc"
      alt=""
    />
    <!-- <video
      class="pr-bg"
      id="pr-bg"
      autoplay
      loop
      muted="true"
      preload="auto"
      playsinline
    >
      <source src="../assets/videos/bg-product.mp4" />
    </video> -->
    <div class="pr-header">
      <p class="pr-title">Making sure every member enjoy the journey</p>
      <a href="#job" class="btn-header">Search jobs</a>
      <!-- <div class="icon-pause">
        <div v-if="isActive">
          <img @click="pauseVid" src="../assets/images/iconplay.svg" alt="" />
        </div>
        <div v-else>
          <img @click="playVid" src="../assets/images/iconpasue.svg" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vid1: "",
      isActive: true,
    };
  },
  methods: {
    pauseVid() {
      this.isActive = false;
      this.vid1.pause();
    },

    playVid() {
      this.isActive = true;
      this.vid1.play();
    },
  },
  mounted() {
    this.vid1 = document.getElementById("pr-bg");
  },
};
</script>

<style lang="scss" scoped>
.about-baner {
  position: relative;
  margin-top: 64px;
  .pr-bg {
    width: 100%;
  }
  .pr-header {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-flex;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    width: 100%;
    height: 464px;
    line-height: 464px;
    padding: 0 20px;
  }
  img {
    height: 464px;
    object-fit: cover;
  }

  .btn-header {
    border: none;
    outline: none;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 22px;
    padding: 21px 40px;
    background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
    border-radius: 40px;
    text-align: center;
    color: #fafafa;
    margin-top: 40px;
    text-decoration: none;
  }
  .pr-title {
    font-size: 56px;
    line-height: 6.7rem;
    font-weight: 600;
    max-width: 728px;
    margin: 0 auto;
    color: #363636;
    text-align: center;
  }
  .icon-pause {
    color: white;
    font-size: 80px;
    text-align: center;
    margin-top: 56px;
  }
}
.is-hidden-pc {
  display: none;
}
.is-hidden-mobile {
  display: block;
}
@media (max-width: 1024px) {
  .about-baner {
    .pr-header {
      top: 52%;
    }
    .pr-bg {
      width: 300% !important;
    }
    .pr-title {
      width: 100%;
    }
  }
}
@media (max-width: 739px) {
  .is-hidden-pc {
    display: block;
  }
  .is-hidden-mobile {
    display: none;
  }
  .about-baner {
    margin-top: 56px;
    .pr-bg {
      width: 300% !important;
      transform: translateX(-33%);
      width: 300px;
    }
    .pr-header {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .pr-title {
      font-size: 3.6rem;
      line-height: 4.3rem;
    }
    .icon-pause {
      color: white;
      font-size: 80px;
      text-align: center;
      margin-top: 56px;
    }
    .btn-header {
      padding: 12px 40px;
      margin-top: 24px;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}
@media (max-width: 353px) {
  .about-baner {
    .pr-title {
      width: 100%;
      font-size: 2rem;
      line-height: 4.3rem;
    }
  }
}
</style>
