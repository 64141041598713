<template>
  <div class="brave" id="job">
    <div class="container">
      <div class="brave-header">Brave thinkers wanted</div>
      <div class="brave-header-des">
        We started with a little white card reader but haven’t stopped there.
        Our purpose is economic empowerment, and we’re building tools to help
        sellers of all sizes succeed on their own terms.
      </div>
      <div class="brave-content">
        <div class="brave-content1">
          <div class="brave-content1-title">ACCOUNT MANAGER</div>
          <div class="brave-content-img">
            <img src="../assets/images/+.svg" style="cursor: pointer" alt="" />
          </div>
        </div>
        <div class="brave-content1">
          <div class="brave-content1-title">JUNIOR ACCOUNTANT</div>
          <div class="brave-content-img">
            <img src="../assets/images/+.svg" style="cursor: pointer" alt="" />
          </div>
        </div>
        <div class="brave-content1">
          <div class="brave-content1-title">BACKEND DEVELOPER</div>
          <div class="brave-content-img">
            <img src="../assets/images/+.svg" style="cursor: pointer" alt="" />
          </div>
        </div>
        <div class="brave-content1">
          <div class="brave-content1-title">PRODUCT DESIGNER</div>
          <div class="brave-content-img">
            <img src="../assets/images/+.svg" style="cursor: pointer" alt="" />
          </div>
        </div>
      </div>
      <div class="brave-detail">
        <div class="brave-detail-title">View all oppotunities</div>
        <img
          style="cursor: pointer"
          class="icon-brave-detail"
          src="../assets/images/weview.svg"
          alt=""
        />
      </div>
    </div>
    <div class="popup popup1">
      <div class="popup1-title">UPDATE...</div>

      <img
        src="../assets/images/icon-close.svg"
        style="cursor: pointer"
        class="icon-close1"
        alt=""
      />
    </div>
    <div class="popup popup2">
      <div class="popup2-title">UPDATE...</div>
      <img
        src="../assets/images/icon-close.svg"
        style="cursor: pointer"
        class="icon-close2"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var iconDetail = document.querySelectorAll(".brave-content-img");
    var iconDetailSum = document.querySelector(".icon-brave-detail");
    var iconClose1 = document.querySelector(".icon-close1");
    var iconClose2 = document.querySelector(".icon-close2");
    var popup1 = document.querySelector(".popup1");
    var popup2 = document.querySelector(".popup2");
    var popup = document.querySelector(".popup");
    iconDetail.forEach((x) => {
      x.addEventListener("click", function () {
        popup1.style.transform = "translateY(0)";
      });
    });
    iconDetailSum.addEventListener("click", function () {
      popup2.style.transform = "translateY(0)";
    });

    iconClose1.addEventListener("click", () => {
      popup.style.transform = "translateY(100%)";
    });
    iconClose2.addEventListener("click", () => {
      popup2.style.transform = "translateY(100%)";
    });
  },
};
</script>

<style lang="scss" scoped>
.brave {
  padding: 113px 0;
  text-align: center;
  background: #fafafa;
  margin-top: 113px;
}
.brave-header {
  font-size: 56px;
  font-weight: 600;
  line-height: 67px;
  color: #27282b;
}
.brave-header-des {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  color: #999;

  max-width: 886px;
  text-align: center;
  margin: 0 auto;
  margin-top: 48px;
}

.brave-content {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 125px;

  .brave-content1 {
    max-width: 620px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    height: 130px;
    &-title {
      font-size: 24px;
      font-weight: 600;
      color: #363636;
    }
  }
}
.brave-detail {
  margin-top: 64px;
  text-align: center;
  &-title {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img {
    margin-top: 11px;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  z-index: 4000;
  transform: translateY(100%);
  transition: 0.4s ease;
}
.popup1-title {
  font-size: 50px;
  font-weight: 600;
}
.popup2-title {
  font-size: 50px;
  font-weight: 600;
}
.icon-close1,
.icon-close2 {
  position: fixed;
  top: 50px;
  right: 50px;
}
@media (max-width: 1320px) {
  .brave {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 739px) {
  .brave {
    padding: 6.4rem 2rem;
    margin-top: 6.4rem;
  }
  .brave-header {
    font-size: 3.6rem;
    line-height: 4.3rem;
  }
  .brave-header-des {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-top: 2.4rem;
  }
  .brave-content {
    margin-top: 4.8rem;
    display: grid;
    grid-column-gap: 125px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "a1"
      "a2"
      "a3"
      "a4";
    .brave-content1 {
      max-width: 620px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      height: 75px;
      &-title {
        font-size: 1.8rem;
        line-height: 2.7rem;
        font-weight: 600;
        color: #363636;
      }
      .brave-content-img {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .brave-content1:nth-child(1) {
    grid-area: a1;
  }
  .brave-content1:nth-child(2) {
    grid-area: a3;
  }
  .brave-content1:nth-child(3) {
    grid-area: a2;
  }
  .brave-content1:nth-child(4) {
    grid-area: a4;
  }
  .brave-detail {
    margin-top: 4.8rem;
    text-align: center;
    img {
      margin-top: 8px;
    }
  }
}
</style>
