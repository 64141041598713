<template>
  <section id="contact">
    <div class="contact_us">
      <div class="contact_header">
        <p>Contact us</p>
        <span>Let’s work together</span>
      </div>
      <div class="contact_item">
        <div class="container">
          <div class="map">
<!--            <div class="map_item">-->
<!--              <img src="../assets/images/sing.png" alt="" />-->
<!--              <p class="map_text">-->
<!--                12 Staple St, Seventeen<br />-->
<!--                Mile Rocks QLD 4073, Australia<br />-->

<!--                <a-->
<!--                    href="tel:(+61) 403 307 600"-->
<!--                    style="text-decoration: none; color: #363636"-->
<!--                    target="_blank"-->
<!--                >-->
<!--                  Tel: (+61) 403 307 600</a-->
<!--                >-->
<!--              </p>-->
<!--            </div>-->
            <div class="map_item">
              <img src="../assets/images/singapore.png" alt="" />
              <p class="map_text">
                NUSS, 9 Kent Ridge Dr,<br>
                Singapore 119241 <br>
                <a
                  href="tel:(+65) 8596 7513"
                  style="text-decoration: none; color: #363636"
                  target="_blank"
                >
                  Tel: (+65) 8596 7513</a
                >
              </p>
            </div>
<!--            <div class="map_item">-->
<!--              <img src="../assets/images/Boston.png" alt="" />-->
<!--              <p class="map_text">-->
<!--                100 Huntington Ave,<br />-->
<!--                Boston MA 02116, USA<br />-->
<!--                <a-->
<!--                  href="tel:(+1) 978 619 9999"-->
<!--                  style="text-decoration: none; color: #363636"-->
<!--                  target="_blank"-->
<!--                  >Tel: (+1) 978 619 9999</a-->
<!--                >-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="map_item">-->
<!--              <img-->
<!--                src="../assets/images/vn.png"-->
<!--                alt=""-->
<!--                style="max-height: 121px"-->
<!--              />-->
<!--              <p class="map_text">-->
<!--                Vien Dong Building, <br> 36 Hoang Cau,<br />-->
<!--                Dong Da District, Hanoi, Vietnam <br />-->
<!--                <a-->
<!--                  href="tel:096 333 9911"-->
<!--                  style="text-decoration: none; color: #363636"-->
<!--                  target="_blank"-->
<!--                  >Tel: (+84) 96 333 9911</a-->
<!--                >-->
<!--              </p>-->
<!--            </div>-->
          </div>
        </div>
        <div class="social">
          <a class="email" href="mailto:business@globalvirality.com" target="_blank">
            Email: business@globalvirality.com
          </a>
          <div class="social_list">
            <div class="social_iem">
              <a href="https://www.facebook.com/globalvirality" target="_blank">
                <div>
                  <img src="../assets/images/facebook.svg" alt="" />
                </div>
              </a>
            </div>
            <div class="social_iem">
              <a href="https://www.linkedin.com/company/global-virality/" target="_blank">
                <div>
                  <img src="../assets/images/linkin.svg" alt="" />
                </div>
              </a>
            </div>
<!--            <div class="social_iem">-->
<!--              <div>-->
<!--                <img src="../assets/images/youtube.svg" alt="" />-->
<!--              </div>-->
<!--            </div>-->
            <div class="social_iem">
              <a href="https://twitter.com/Global_Virality" target="_blank">
                <div>
                  <img src="../assets/images/twitter.svg" alt="" />
                </div>
              </a>
            </div>
          </div>
          <p class="social_footer">Copyright {{ allyear }} Global Virality LLP.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    allyear: function () {
      {
        const date = new Date();
        const year = date.getFullYear();
        return "@".concat(year);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.contact_us {
  width: 100%;
  background: #fafafa;
  position: relative;
  padding-bottom: 67px;
  .contact_header {
    padding-top: 110px;
    text-align: center;

    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.92rem;
      color: #999999;
      padding-bottom: 5px;
    }

    span {
      font-size: 4rem;
      line-height: 4.8rem;
      font-weight: 600;
      padding-top: 5px;
      color: #27282b;
    }
  }
  .contact_item {
    background-image: url("../assets/images/contact.png");
    mix-blend-mode: darken;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .map {
      display: flex;
      //justify-content: space-between;
      justify-content: center;
      padding-top: 12.506rem;
      //flex-wrap: wrap;
      .map_item {
        width: 33.33%;
        text-align: center;
        .map_text {
          font-size: 2rem;
          line-height: 3.6rem;
          font-weight: 400;
          padding-top: 35.58px;
          color: #363636;
        }
      }
    }
    .social {
      text-align: center;
      padding-top: 11rem;
      .email {
        font-size: 2.4rem;
        line-height: 3.6rem;
        font-weight: 400;
        color: #363636;
        text-decoration: none;
      }
      .social_list {
        display: flex;
        justify-content: center;
        padding-top: 2.5rem;
        position: relative;
        .social_iem {
          margin: 0px 16px;
          position: static;
          width: 44px;
          height: 44px;
          left: 0px;
          top: 0px;
          border: 1px solid #e6e6e6;
          box-sizing: border-box;
          border-radius: 100px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-top: 2px;
          }
        }
        .backto {
          position: absolute;
          right: 66px;

          bottom: -64px;
        }
      }
      .social_footer {
        color: #363636;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.92rem;
        letter-spacing: 0.05em;
        padding-top: 3.2rem;
      }
    }
  }
}
//tablet&mobile
@media (max-width: 1024px) {
  .contact_us {
    padding-bottom: 64px;
    .contact_header {
      padding-top: 64px;
      p {
      }
      span {
        font-size: 3.6rem;
        line-height: 4.32rem;
      }
    }
    .contact_item {
      background-image: none;

      .map {
        padding-top: 6.41rem;
        flex-wrap: wrap;
        .map_item {
          width: 100%;
          text-align: center;
          padding-top: 64px;

          .map_text {
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 400;
            padding-top: 15.58px;
          }
        }
        .map_item:first-child {
          padding-top: 0;
        }
      }
      .social {
        padding-top: 6.4rem;
        .email {
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 400;
          color: #363636;
        }
        .social_list {
          padding: 1.6rem 0;
          .social_iem {
          }
        }
        .social_footer {
          padding: 0;
        }
      }
    }
  }
}

//moblie
@media (max-width: 739px) {
  .contact_us {
    .contact_header {
      padding-top: 64px;
    }
  }
}
//tablet
@media (min-width: 740px) and (max-width: 1024px) {
}
</style>
