<template>
  <section class="about">
    <about-banner />
    <aboutTitleReimagining />
    <teamSlide />
    <aboutWeBelieve />
    <aboutStory />
    <aboutSupport />
    <aboutBrave />
    <aboutPress />
    <aboutFind />
    <contactUs />
  </section>
</template>

<script>
import aboutBanner from "../component-about/aboutBanner.vue";
import teamSlide from "../components/TeamSlide.vue";
import aboutTitleReimagining from "../component-about/aboutTitleReimagining.vue";
import aboutWeBelieve from "../component-about/aboutWeBelieve.vue";
import aboutStory from "../component-about/aboutStory.vue";
import aboutSupport from "../component-about/aboutSupport.vue";
import aboutFind from "../component-about/aboutFind.vue";
import contactUs from "../components/ContactUs.vue";
import aboutBrave from "../component-about/aboutBrave.vue";
import aboutPress from "../component-about/aboutPress.vue";
export default {
  components: {
    aboutBanner,
    teamSlide,
    aboutTitleReimagining,
    aboutWeBelieve,
    aboutStory,
    aboutSupport,
    aboutFind,
    contactUs,
    aboutBrave,
    aboutPress,
  },
};
</script>

<style></style>
>
