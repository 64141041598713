<template>
  <section id="teammate">
    <div class="teammate">
      <div class="teammate-content container">
        <div class="title-teammate">
          <div class="title-folder-center">Founders</div>
        </div>
        <FounderMember />

        <div class="title-teammate">
          <div class="title-folder-center style-execu">... and the crew</div>
        </div>
      </div>
      <CrewTeam />
    </div>
  </section>
</template>
<script>
import FounderMember from "../components/FounderMember.vue";
import CrewTeam from "../components/CrewTeam";
export default {
  components: { FounderMember, CrewTeam },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.teammate {
  background-repeat: no-repeat;
  background-size: cover;
  .teammate-content {
    padding: 64px 0 0px 0;
  }

  .title-folder-top {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 400;
    color: #999999;
  }
  .title-folder-center {
    font-size: 4rem;
    line-height: 4.8rem;
    font-weight: 600;
    // margin-top: 5px;
    color: #27282b;
  }
  .style-execu {
    margin-top: 110px;
    margin-bottom: 48px;
  }
  .title-teammate {
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 48px;
    text-align: center;
  }
  // tablet & mobile
  @media (max-width: 1024px) {
    .title-teammate {
      text-align: center;
      .title-folder-top {
        justify-content: center;
      }
      .title-folder-center {
        font-size: 3.6rem;
        line-height: 4.3rem;
      }
      .title-folder-bottom {
        line-height: 28px;
      }
    }
  }
  // tablet
  @media (min-width: 740px) and (max-width: 1024px) {
    .style-execu {
      margin-top: 6.4rem;
    }
    .teammate-content {
      padding: 80px 0;
      .hide-on-tablet {
        display: none;
      }
    }
  }
  // mobile
  @media (max-width: 739px) {
    .title-teammate {
      .title-folder-center {
        font-size: 2.4rem;
        line-height: 3.8rem;
        margin-bottom: 4.8rem;
      }
    }

    .teammate-content {
      padding: 4.8rem 0;

      .hide-on-mobile {
        display: none;
      }
      .title-teammate {
        text-align: center;
        padding: 0px 20px;
        margin-bottom: 0;

        .title-folder-top {
          justify-content: unset;
          span {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .title-folder-bottom {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
