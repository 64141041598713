<template>
  <div class="board-number">
    <div class="board-number-one" @click="handleShowBio(0)">
      <div class="sack-img-bio">
        <img class="image-banner" src="../assets/images/peter_thai.png" alt="" />
        <div class="bio">
          "I obtained a Ph.D. in Engineering from NUS within two years and subsequently worked as a Research Scientist
          in Defense projects for another two years, where I gained valuable experience in the field.
          I also explored entrepreneurship beyond my scientific pursuits,
          where I have acted as an angel investor and provided marketing and technical guidance in the blockchain industry."
        </div>
      </div>
      <div class="teammate-name">Peter Thai</div>
      <div class="teammate-des">Co-Founder</div>
    </div>
    <div class="board-number-one" @click="handleShowBio(1)">
      <div class="sack-img-bio">
        <img class="image-banner" src="../assets/images/jack_siow.png" alt="" />
        <div class="bio">
          "I am a seasoned technology professional with more than 10 years of experience in the field,
          with a wealth of expertise in business development, project and sales management,
          and manufacturing process improvement. My track record speaks for itself,
          as I have demonstrated time and again the ability to deliver successful projects on schedule and within budget."
        </div>
      </div>
      <div class="teammate-name">Jack Siow</div>
      <div class="teammate-des">Chief Business Officer</div>
    </div>
    <div class="board-number-one" @click="handleShowBio(2)">
      <div class="sack-img-bio">
        <img class="image-banner" src="../assets/images/Tuan Hua.png" alt="" />
        <div class="bio">
          "18+ years in tech startups in C-suite leadership positions. Built Vietnam’s first cloud-based POS platform (maybanhang.net, serving 15K+ merchants), Vietnam’s first stock exchange platform (Kim Long Securities), and the world’s first blockchain loyalty app (EZToken Rewards)."
        </div>
      </div>
      <div class="teammate-name">Tony Hua</div>
      <div class="teammate-des">Chief Technology Officer</div>
    </div>
<!--    <div class="board-number-one">-->
<!--      <div class="sack-img-bio">-->
<!--        <img-->
<!--          class="image-banner"-->
<!--          src="../assets/images/David Tran.png"-->
<!--          alt=""-->
<!--        />-->
<!--        <div class="bio">-->
<!--          "Computer Scientist and Professor at the University of Massachusetts-->
<!--          (USA) with 15+ years of blockchain-related research. An entrepreneur,-->
<!--          investor, and advisor in blockchain space, especially interested in-->
<!--          applying blockchain to the physical world"-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="teammate-name">David Tran</div>-->
<!--      <div class="teammate-des">Blockchain Professor</div>-->
<!--    </div>-->
<!--    <div class="board-number-one">-->
<!--      <div class="sack-img-bio">-->
<!--        <img-->
<!--          class="image-banner"-->
<!--          src="../assets/images/thanhnguyen.png"-->
<!--          alt=""-->
<!--        />-->
<!--        <div class="bio">-->
<!--          "15+ years of leadership in Business Development and Technology.-->
<!--          Deputy General Director & IT Chief of LienVietPostBank. Former Head of-->
<!--          Marketing Research & Mobile Service at Viettel Group. Former Director-->
<!--          of Product at Huawei Vietnam"-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="teammate-name">Thanh Nguyen</div>-->
<!--      <div class="teammate-des">Chief Business Officer</div>-->
<!--    </div>-->
<!--    <div class="board-number-one">-->
<!--      <div class="sack-img-bio">-->
<!--        <img-->
<!--          class="image-banner"-->
<!--          src="../assets/images/truongnguyen.png"-->
<!--          alt=""-->
<!--        />-->
<!--        <div class="bio">-->
<!--          "Founder/CEO of Van Dough, a fast growing bakery chain in Brisbane,-->
<!--          Australia with $20m yearly revenue. An early enthusiast, entrepreneur,-->
<!--          and investor in blockchain business, having led as CEO of EZToken-->
<!--          Rewards, the world's first crypto loyalty app."-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="teammate-name">Van Nguyen</div>-->
<!--      <div class="teammate-des">Chief Global Officer</div>-->
<!--    </div>-->
    <el-dialog :visible.sync="dialogVisible" class="dialog-bio">
      <p class="dialog-title">{{ list[index].title }}</p>
      <p class="dialog-position">{{ list[index].position }}</p>
      <p class="dialog-bio">{{ list[index].bio }}</p>
    </el-dialog>

  </div>
</template>

<script>
export default {

  data(){
    return{
      index: 0,
      dialogVisible: false,
      list: [
        {
          title: 'Peter Thai',
          position: 'Chief Operation Officer',
          bio: `I obtained a Ph.D. in Engineering from NUS within two years and subsequently worked as a Research Scientist
          in Defense projects for another two years, where I gained valuable experience in the field.
          I also explored entrepreneurship beyond my scientific pursuits,
          where I have acted as an angel investor and provided marketing and technical guidance in the blockchain industry.`
        },

        {
          title: 'Jack Siow',
          position: 'Chief Business Officer',
          bio: `I am a seasoned technology professional with more than 10 years of experience in the field,
          with a wealth of expertise in business development, project and sales management,
          and manufacturing process improvement. My track record speaks for itself,
          as I have demonstrated time and again the ability to deliver successful projects on schedule and within budget.`
        },
        {
          title: 'Tuan Hua',
          position: 'Chief Technology Officer',
          bio: '18+ years in tech startups in C-suite leadership positions. Built Vietnam’s first cloud-based POS platform (maybanhang.net, serving 15K+ merchants), Vietnam’s first stock exchange platform (Kim Long Securities), and the world’s first blockchain loyalty app (EZToken Rewards).'
        },

      ]
    }
  },
  methods: {
    handleShowBio(index) {
      if (window.innerWidth < 768) {
        this.index = index
        this.dialogVisible = true
      }
    }
  }


};
</script>

<style lang="scss" scoped>
::v-deep.dialog-bio {
  .el-dialog {
    background: #21242a;
    width: calc(100vw - 30px);
    * {
      color: #fff;
    }

    &__header {
      .el-icon-close {
        font-size: 24px;
      }
    }
    &__body {
      padding: 10px 20px 30px;
      text-align: left;
      .dialog-title {
        font-size: 34px;
        line-height: 58px;

        @media screen and (max-width: 390px) {
          font-size: 30px;
        }
      }
      .dialog-position {
        margin-bottom: 25px;
        font-size: 20px;
        line-height: 36px;
      }
      .dialog-bio {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        word-break: break-word;
      }
    }
  }
}

@keyframes chageHover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.sack-img-bio:hover .bio , .sack-img-bio:active .bio{
  display: block;
  opacity: 1;
}
.bio {
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
  //width: 77%;
  width: 58%;
  height: 100%;
  background: url(../assets/images/hover.png) no-repeat;
  background-size: inherit;
  display: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  transition: 1s ease;
  opacity: 0;
  color: #fafafa;
  text-align: center;
  cursor: pointer;
  padding-top: 7%;
  padding-left: 17px;
  padding-right: 16px;
  animation: chageHover linear 0.5s;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.bio::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bio {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.board-number {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  @media (max-width: 1028px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  @media (max-width: 739px) {
    grid-template-columns: 1fr;
    grid-gap: 48px;
  }
  .teammate-name {
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 400;
    color: #414c63;
    margin-top: 2.9rem;
    @media (max-width: 739px) {
      font-size: 1.8rem;
      margin-top: 1rem;
    }
  }
  .teammate-des {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 400;
    color: #c4c4c4;

    @media (max-width: 739px) {
      font-size: 1.4rem;
    }
  }
}
@media (max-width: 1300px) {
  .bio {
    //background: none;
    display: none !important;

  }
}
.sack-img-bio {
  position: relative;
  @media (max-width: 739px) {
    .image-banner {
      height: 224px;
    }
    //.bio{
    //  display: block !important;
    //  background-size: 201px 224px;
    //  left: 50%;
    //  width: 203px
    //}
  }
}
</style>
